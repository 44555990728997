html,
body {
  height: 100%;
}

/* body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
} */

#logo {
  width: 42px;
}

section {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
}

#mPg {
  background-color: #dee2e6;
}

/* tbody{
    overflow: auto;
} */

.navbar-nav {
  text-align: center;
  /* margin: 0px 0px 0px auto; */
}

#spaceTab a {
  color: gray !important;
  font-size: 14px;
}

#spaceTab a:hover {
  color: white !important;
}

.space {
  cursor: pointer;
}

.space div:hover {
  border: 4px rgb(10, 22, 39) solid;
}

.ui-widget-header,
.ui-state-default,
ui-button {
  /* background:rgb(59, 139, 177);

    border: 1px solid rgb(59, 139, 177); */

  background: #dc3545;

  border: 1px solid #dc3545;

  color: #ffffff;

  font-weight: bold;
}

.no-close .ui-dialog-titlebar-close {
  display: none;
}

/* Sortable tables */
table.sortable thead {
  background-color: #eee;
  color: #000000;
  font-weight: bold;
  font-size: large;
  cursor: default;
}
